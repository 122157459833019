import * as React from "react"
import {
	useStaticQuery,
	graphql,
	type PageProps,
	navigate,
	HeadProps,
} from "gatsby"
import clsx from "clsx"

import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { SearchInput } from "../components/SearchInput"
import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import PageDataBodyPageIntro from "../slices/PageDataBodyPageIntro"
import { siteSearch } from "../lib/siteSearch"
import { UnderlineLink } from "../components/UnderlineLink"

import type { SearchIndexQuery } from "../graphql.gen"
import { Spinner } from "../components/Spinner"
import { SearchGrouping } from "../components/SearchGrouping"

const SEARCH_ENDPOINT =
	process.env.NODE_ENV === "production"
		? "/api/search"
		: "/api/gatsby-plugin-flexsearch/search"
const LIMIT = 10

const SEARCH_TYPES = [
	{ type: "page", displayName: "Pages" },
	{ type: "practice_area", displayName: "Practice Areas" },
	{ type: "blog_post", displayName: "Blog Posts" },
	{ type: "person", displayName: "People" },
]

function useSearchIndex() {
	const result = useStaticQuery<SearchIndexQuery>(graphql`
		query SearchIndex {
			flexsearchPages {
				publicIndexURL
				indexOptions {
					ref
					index
					store
				}
			}
		}
	`)

	const data = result.flexsearchPages

	return React.useMemo(
		() => ({
			url: data?.publicIndexURL,
			options: data?.indexOptions,
		}),
		// We know this data is static and will never change.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
}

export interface SearchResult {
	title: string
	uid: string
	url: string
	type: string
	summary?: string
}

interface SearchState {
	results: SearchResult[]
	mode: "SEARCHED" | "REST" | "END_OF_RESULTS"
}

const SearchPage = ({ location }: PageProps) => {
	const [state, setState] = React.useState<SearchState>({
		mode: "REST",
		results: [],
	})
	const [isSearching, setIsSearching] = React.useState(false)
	const rResultsList = React.useRef<HTMLDivElement>(null)
	const index = useSearchIndex()

	const params = new URLSearchParams(location.search)

	const query = params.get("query") ?? ""

	const skip = params.has("skip")
		? Number.parseInt(params.get("skip") as string)
		: 0

	const nextSkipParams = new URLSearchParams(location.search)
	nextSkipParams.set("skip", String(skip + LIMIT))

	const submitSearch = React.useCallback(
		async (query: string) => {
			setIsSearching(true)
			const res = await fetch(SEARCH_ENDPOINT, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					query,
					indexURL: location.origin + index.url,
					indexOptions: index.options,
					limit: LIMIT,
					skip,
				}),
			})

			const results = (await res.json()) as SearchResult[]
			const mode =
				results.length > 0 && results.length < LIMIT
					? "END_OF_RESULTS"
					: "SEARCHED"

			if (skip > 0) {
				// If we're dealing with a "Load More", just append the new results.
				setState((prev) => ({
					...prev,
					mode,
					results: [...prev.results, ...results],
				}))
			} else {
				// Otherwise, this is a new query, so just overwrite previous ones.
				setState({ mode, results })
			}

			setIsSearching(false)
		},
		[index.options, index.url, location.origin, skip]
	)

	// TODO: Refactor later
	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		const target = e.target as typeof e.target & {
			query: HTMLInputElement
		}

		siteSearch({ query: target.query.value, skip: 0 })
	}

	React.useEffect(() => {
		if (!query) return

		submitSearch(query)
	}, [query, submitSearch])

	const loadMoreHref = "/search?" + nextSkipParams.toString()

	const onLoadMoreClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault()

		navigate(loadMoreHref)
	}

	React.useEffect(() => {
		if (skip === 0) return

		rResultsList.current?.scrollIntoView({ block: "end" })
	}, [skip])

	return (
		<>
			<PageDataBodyPageIntro heading="Search Results" />

			<BoundedBox.Outer className="bg-white" ref={rResultsList}>
				<BoundedBox.Inner className="grid gap-10 md:gap-12 lg:gap-16">
					<div className="flex items-center space-x-5 ">
						<SearchInput
							variant="border"
							onSubmit={handleSubmit}
							className="max-w-sm"
							placeholder="Search..."
							name="query"
							defaultValue={query}
						/>

						<Spinner
							className={clsx(
								"w-7 h-7 text-black",
								"transition-opacity",
								isSearching ? "opacity-100" : "opacity-0"
							)}
						/>
					</div>

					<ul className="flex flex-col space-y-12 md:space-y-14 lg:space-y-20">
						{SEARCH_TYPES.map(({ displayName, type }) => {
							const results = state.results.filter(
								(result) => result.type === type
							)

							if (results.length <= 0) return null

							return (
								<SearchGrouping
									key={type}
									results={results}
									displayName={displayName}
								/>
							)
						})}

						{state.mode === "SEARCHED" && state.results.length <= 0 && (
							<Text variant="heading4">
								No results found. Please try another search query.
							</Text>
						)}
					</ul>

					{state.mode === "SEARCHED" && state.results.length >= 1 && (
						<UnderlineLink
							href={loadMoreHref}
							className="mx-auto mt-5"
							onClick={onLoadMoreClick}
						>
							Load More Results
						</UnderlineLink>
					)}

					{state.mode === "END_OF_RESULTS" && state.results.length >= 1 && (
						<Text variant="heading4" className="text-center" uppercase>
							End of results
						</Text>
					)}
				</BoundedBox.Inner>
			</BoundedBox.Outer>
		</>
	)
}

export const Head = ({ location }: HeadProps) => {
	const settings = useSiteSettings()

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Search"
			pathname={location.pathname}
			twitter={settings.twitter}
			openGraph={settings.openGraph}
		/>
	)
}

export default SearchPage
