import { Link } from "gatsby"
import { Text } from "../components/Text"

import type { SearchResult } from "../pages/search"

interface SearchGroupingProps {
	results: SearchResult[]
	displayName: string
}

export const SearchGrouping = ({
	results,
	displayName,
}: SearchGroupingProps) => {
	return (
		<li className="space-y-7 lg:space-y-9 xl:space-y-10">
			<Text variant="heading4" uppercase>
				{displayName}
			</Text>

			<ul className="space-y-7 lg:space-y-9 xl:space-y-10">
				{results.map((result) => (
					<li key={result.title} className="max-w-xl space-y-5">
						<Text variant="heading3">
							<Link to={result.url} className="transition hover:text-red">
								{result.title}
							</Link>
						</Text>

						{result.summary && (
							<Text variant="paragraph1" fontFamily="serif">
								{result.summary}
							</Text>
						)}
					</li>
				))}
			</ul>
		</li>
	)
}
